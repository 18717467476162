import { useLocation } from 'react-router-dom';

/**
 * page가 /Dashboard 일때만 풋터를 노출하며, 나머지는 화면 크기 확보를 위해 비노출
 * @returns 풋터 엘리먼트
 */
const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === '/Dashboard' && (
        <footer className="py-2 bg-light mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              <div className="text-muted">Copyright &copy; 신화정보통신(주)</div>
              <div>Privacy Policy&middot;Terms &amp; Conditions</div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
