import { atom, selector, selectorFamily } from 'recoil';
import {
  defaultRequestObject,
  fetchCompressResponseHandler,
  fetchResponseHandler,
  getApiServerName,
} from '../common';
import moment from 'moment';

/**
 * 상품 조회 시간 atom
 */
export const goodRequestTimeState = atom({
  key: 'goodRequestTimeState',
  default: moment().format('YYYY-MM-DD HH:mm:ss') + '[초회차]',
});

/**
 * 코드리스트를 구합니다.
 */
export const codeListState = selector({
  key: 'codeListState',
  get: async ({ get }) => {
    // eslint-disable-next-line no-unused-vars
    const goodSearchTime = get(goodRequestTimeState);

    const response = await fetch(
      getApiServerName() + '/vanilla/codeList',
      await defaultRequestObject('GET', {}, true),
    );

    return (await fetchResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 상품 리스트를 구합니다.
 */
export const goodListState = selector({
  key: 'goodListState',
  get: async ({ get }) => {
    // eslint-disable-next-line no-unused-vars
    const goodSearchTime = get(goodRequestTimeState);
    const codeList = get(codeListState);

    if (!codeList) return [];

    const response = await fetch(
      getApiServerName() + '/vanilla/goodList/all',
      await defaultRequestObject('GET', {}, true),
    );

    const goodList = (await fetchCompressResponseHandler(response)).message;

    //회사명, id(회사코드|상품코드) 필드 생성
    return goodList.map((e1) => ({
      ...e1,
      IS_CMY_NM: codeList.find((e2) => e2.REFRN_CD === e1.IS_CMY_CD).REFRN_CD_NM,
      IS_CMY_CD_NM: `[${e1.IS_CMY_CD}]${
        codeList.find((e2) => e2.REFRN_CD === e1.IS_CMY_CD).REFRN_CD_NM
      }`,
      id: e1.IS_CMY_CD + '|' + e1.PDT_CD,
      SALE_ST_DT: e1.SALE_ST_DT ? moment(e1.SALE_ST_DT).toDate() : '',
      SALE_ED_DT: e1.SALE_ED_DT ? moment(e1.SALE_ED_DT).toDate() : '',
    }));
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 보장급부 리스트를 구합니다.
 */
export const dsdListState = selector({
  key: 'dsdListState',
  get: async ({ get }) => {
    // eslint-disable-next-line no-unused-vars
    const goodSearchTime = get(goodRequestTimeState);

    const response = await fetch(
      getApiServerName() + '/vanilla/dsdList',
      await defaultRequestObject('GET', {}, true),
    );

    return (await fetchCompressResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 회사코드 atom
 */
export const companyCodeState = atom({
  key: 'companyCodeState',
  default: '',
});

/**
 * 상품코드 atom
 */
export const goodCodeState = atom({
  key: 'goodCodeState',
  default: '',
});

/**
 * 주특약코드 atom
 */
export const riderCodeState = atom({
  key: 'riderCodeState',
  default: '',
});

/**
 * 상품별 주특약코드 정렬 상태 atom
 */
export const riderSortstate = atom({
  key: 'riderSortstate',
  default: '주특약순',
});

/**
 * 상품/주특약 선택 상태 atom
 */
export const isGoodOrRiderState = atom({
  key: 'isGoodOrRiderState',
  default: {},
});

/**
 * 주특약별 상품 갯수 알림 스위치 atom
 */
export const isRiderGoodCountState = atom({
  key: 'isRiderGoodCountState',
  default: false,
});

/**
 * 선택할 상품 atom
 */
export const focusGoodState = atom({
  key: 'focusGoodState',
  default: '',
});

/**
 * 선택할 주특약 atom
 */
export const focusRiderState = atom({
  key: 'focusRiderState',
  default: '',
});

/**
 * 주특약관계(MCS)리스트를 구합니다.
 */
export const mcsListState = selector({
  key: 'mcsListState',
  get: async ({ get }) => {
    // eslint-disable-next-line no-unused-vars
    const companyCode = get(companyCodeState);
    if (companyCode === 'reset' || !companyCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/mcsList/${companyCode}`,
      await defaultRequestObject('GET', {}, true),
    );
    return (await fetchCompressResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 주특약(MSC)리스트를 구합니다.
 */
export const mscListState = selector({
  key: 'mscListState',
  get: async ({ get }) => {
    const companyCode = get(companyCodeState);
    if (companyCode === 'reset' || !companyCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/mscList/${companyCode}`,
      await defaultRequestObject('GET', {}, true),
    );
    let list = (await fetchCompressResponseHandler(response)).message;
    //id(급부기준분류코드|급부기준항목코드)필드 생성
    list.forEach((e, i, arr) => {
      e.SEQ = arr.length - i;
      e.id = e.BNFT_BS_CL_CD + '|' + e.BNFT_BS_ITM_CD;
    });

    return { sortType: '', list };
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 상품별 주특약(MSC)리스트를 구합니다.
 */
export const goodMscListState = selector({
  key: 'goodMscListState',
  get: ({ get }) => {
    //상품 아톰 구독
    const goodCode = get(goodCodeState);
    if (!goodCode) return { sortType: '주특약순', list: [] };

    //MCS를 구합니다.
    const mcsList = get(mcsListState);
    if (mcsList.length < 1) return { sortType: '주특약순', list: [] };

    //MSC를 구합니다.
    const mscList = get(mscListState);
    if (mscList.list.length < 1) return { sortType: '주특약순', list: [] };

    //상품별 MCS를 구합니다.
    const goodMcsList = mcsList.filter((e) => e.PDT_CD === goodCode);

    //주특약(MCS) 코드별 MSC를 구합니다.
    const goodMscList = [];
    goodMcsList.forEach((e1) => {
      // return mscList.filter((e2) => e2.MCNR_SCN_CD === e1.MCNR_SCN_CD);
      const arr = mscList.list
        .filter((e2) => e2.MCNR_SCN_CD === e1.MCNR_SCN_CD)
        .map((e3) => ({ ...e3, OPTR: e1.OPTR }));
      goodMscList.push(...arr);
    });

    let sortType = '';
    if (0 < goodMscList.length) {
      //순서데이터가 있으면 순서데이터로, 없으면 주특약 순서로 정렬 해줍니다.
      if (goodMcsList[0].SCN_SO_ODR_NO) {
        sortType = '약관순';
        goodMscList.sort((a, b) => {
          let aMcs = goodMcsList.find((e) => e.MCNR_SCN_CD === a.MCNR_SCN_CD);
          let bMcs = goodMcsList.find((e) => e.MCNR_SCN_CD === b.MCNR_SCN_CD);
          return aMcs.SCN_SO_ODR_NO < bMcs.SCN_SO_ODR_NO ? -1 : 1;
        });
      } else {
        sortType = '주특약순';
        goodMscList.sort((a, b) => {
          if (a.MCNR_SCN_DV_CD < b.MCNR_SCN_DV_CD) return -1;
          if (a.MCNR_SCN_DV_CD > b.MCNR_SCN_DV_CD) return 1;
          //같은 주특약끼리는 주특약명 가나다라순
          return a.MCNR_SCN_NM < b.MCNR_SCN_NM ? -1 : 1;
        });
      }
    }

    goodMscList.forEach((e, i, arr) => (e.SEQ = arr.length - i));

    return { sortType, list: goodMscList };
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 주특약별 상품(GDD) 리스트를 구합니다.
 */
export const riderGoodListState = selector({
  key: 'riderGoodListState',
  get: ({ get }) => {
    //회사코드 아톰 구독
    const companyCode = get(companyCodeState);
    if (!companyCode) return '';

    //주특약 아톰 구독
    const riderCode = get(riderCodeState);
    if (!riderCode) return '';

    //MCS를 구합니다.
    const mcsList = get(mcsListState);
    if (!mcsList) return '';

    //GDD를 구합니다.
    const goodList = get(goodListState);
    if (!goodList) return '';

    //주특약별 MCS를 구합니다.
    const goodMcsList = mcsList.filter((e) => e.MCNR_SCN_CD === riderCode.MCNR_SCN_CD);

    //주특약(MCS) 코드별 GDD를 구합니다.
    //그전에 최적화를 위해 화시별GDD를 미리 생성
    const companyGoodList = goodList.filter((e) => e.IS_CMY_CD === companyCode);
    const goodMscList = goodMcsList.map((e1) => {
      return companyGoodList.find((e2) => e2.PDT_CD === e1.PDT_CD && e2.IS_CMY_CD === companyCode);
    });

    //주특약 순서로 정렬 해줍니다.
    // goodList.sort((a, b) => (a.MCNR_SCN_DV_CD < b.MCNR_SCN_DV_CD ? -1 : 1));

    return goodMscList;
  },
});

/**
 * 상세 주특약(MSS)리스트를 구합니다.
 */
export const mssListState = selector({
  key: 'mssListState',
  get: async ({ get }) => {
    const companyCode = get(companyCodeState);
    if (companyCode === 'reset' || !companyCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/mssList/${companyCode}`,
      await defaultRequestObject('GET', {}, true),
    );
    return (await fetchCompressResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 선택된 주특약별 상세(MSC+MSS) 단건 데이터를 구합니다.
 */
export const mscMssState = selector({
  key: 'mscMssState',
  get: ({ get }) => {
    const riderCode = get(riderCodeState);
    if (!riderCode) return;

    const mssList = get(mssListState);
    if (!mssList) return;

    //선택한 주특약에 대한 MSS 단건 제공
    return mssList.find(
      (e) =>
        e.BNFT_BS_CL_CD === riderCode.BNFT_BS_CL_CD &&
        e.BNFT_BS_ITM_CD === riderCode.BNFT_BS_ITM_CD &&
        e.MCNR_SCN_CL_CD === riderCode.MCNR_SCN_CD,
    );
  },
});

/**
 * 전체 보장 (DIF)리스트를 구합니다.
 */
export const difListState = selector({
  key: 'difListState',
  get: async ({ get }) => {
    const companyCode = get(companyCodeState);
    if (companyCode === 'reset' || !companyCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/difList/${companyCode}`,
      await defaultRequestObject('GET', {}, true),
    );
    return (await fetchCompressResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 주특약별 보장(DIF) 리스트를 구합니다.
 */
export const riderDifListState = selector({
  key: 'riderDifListState',
  get: ({ get }) => {
    //전체 보장 구독
    const difList = get(difListState);
    if (!difList) return [];

    //선택 주특약 코드 아톰 구독
    const { BNFT_BS_CL_CD, BNFT_BS_ITM_CD } = get(riderCodeState);
    if ((BNFT_BS_CL_CD || BNFT_BS_ITM_CD) === false) return [];

    //DSD 구독
    const dsdList = get(dsdListState);

    //선택된 주특약 코드에 대한 보장패턴코드(DIF) 필터링 및 깊은 복사
    const selectedDifList = JSON.parse(
      JSON.stringify(
        difList.filter(
          (e) => e.BNFT_BS_CL_CD === BNFT_BS_CL_CD && e.BNFT_BS_ITM_CD === BNFT_BS_ITM_CD,
        ),
      ),
    );

    //복사본 DIF내에 DSD 배열 생성
    const computedDifList = selectedDifList.map((e1) => {
      //DSD급부별 배열 생성 및 보장금액 계산
      const computedDsdList = dsdList
        .filter((e2) => e2.DAT_BS_CD === e1.DAT_BS_CD)
        .map((e3) => {
          //1 * DIF배수 * DSD배수, 소숫점 1자리까지 표현한다.
          const COVERAGE_AMT = Number((Number(e1.PCN_VAL) * Number(e3.BAS_VAL)).toFixed(1));
          return { ...e3, COVERAGE_AMT };
        });

      //DIF에 DSD배열을 조립
      return { ...e1, computedDsdList };
    });

    //합계 레코드 생성 및 추가
    const totalDifRecord = [...computeCoverageSubTotal(computedDifList), ...computedDifList];

    //DSD 배열을 프로퍼티로 피벗
    const pivotedComputedDifList = totalDifRecord.map((e1) => {
      //DIF에 DSD배열을 필드로 피벗 시킴
      const pivotedDsd = e1.computedDsdList.reduce((prev, cur) => {
        prev['C_' + cur.BNFT_DSGN_CD] = cur.COVERAGE_AMT;
        return prev;
      }, {});

      //DIF에 DSD배열과 피벗된 필드를 조립
      return { ...e1, ...pivotedDsd };
    });

    //보장급부를 급부코드순, 시작/종료, 기소/기대, 연소/연대 순으로 정렬
    pivotedComputedDifList
      .filter((e) => e.DAT_BS_CD !== '합산')
      .sort((a, b) => {
        if (Number(a.DAT_BS_CD) < Number(b.DAT_BS_CD)) return -1;
        if (Number(a.DAT_BS_CD) > Number(b.DAT_BS_CD)) return 1;

        if (a.CNR_ST_DT < b.CNR_ST_DT) return -1;
        if (a.CNR_ST_DT > b.CNR_ST_DT) return 1;

        if (a.CNR_ED_DT < b.CNR_ED_DT) return -1;
        if (a.CNR_ED_DT > b.CNR_ED_DT) return 1;

        if (a.ELPS_PD_MIN_VAL < b.ELPS_PD_MIN_VAL) return -1;
        if (a.ELPS_PD_MIN_VAL > b.ELPS_PD_MIN_VAL) return 1;

        if (a.ELPS_PD_MAX_VAL < b.ELPS_PD_MAX_VAL) return -1;
        if (a.ELPS_PD_MAX_VAL > b.ELPS_PD_MAX_VAL) return 1;

        if (a.IS_AE_MIN_VAL < b.IS_AE_MIN_VAL) return -1;
        if (a.IS_AE_MIN_VAL > b.IS_AE_MIN_VAL) return 1;

        if (a.IS_AE_MAX_VAL < b.IS_AE_MAX_VAL) return -1;
        if (a.IS_AE_MAX_VAL > b.IS_AE_MAX_VAL) return 1;

        return 0;
      });

    return pivotedComputedDifList;
  },
});

/**
 * 주특약별 보장(DIF) 리스트용 합계 Dif리스트를 생성합니다.
 * @param {*} computedDifList 연산된Dif리스트
 * @returns 합계된 Dif 리스트
 */
const computeCoverageSubTotal = (computedDifList) => {
  const SubTotalList = computedDifList.reduce((accu1, nextE1) => {
    //합산레코드가 이미 있는지 해당 키/ 시작/종료, 기소/기대, 연소/연대로 값이 있는지 확인, 없으면 새로운 합산 레코드 생성
    let existRecord = accu1.find(
      (e2) =>
        e2.CNR_ST_DT === nextE1.CNR_ST_DT &&
        e2.CNR_ED_DT === nextE1.CNR_ED_DT &&
        e2.ELPS_PD_MIN_VAL === nextE1.ELPS_PD_MIN_VAL &&
        e2.ELPS_PD_MAX_VAL === nextE1.ELPS_PD_MAX_VAL &&
        e2.IS_AE_MIN_VAL === nextE1.IS_AE_MIN_VAL &&
        e2.IS_AE_MAX_VAL === nextE1.IS_AE_MAX_VAL,
    );
    //지급횟수가 0이면 1로, 그외는 그대로 전달
    const PN_UCN = nextE1.PN_UCN || 1;
    //합산레코드가 기존재 하면
    if (existRecord) {
      //합산레코드에 카운터 증가
      existRecord.PCN_VAL++;
      //합산레코드에 신규 레코드의 급부 보장금액을 합산시킴
      existRecord = nextE1.computedDsdList.reduce((accu2, nextE2) => {
        //합산 레코드에 해당 급부가 존재하면 합산, 없으면 신규 추가
        const existCoverageCode = accu2.computedDsdList.find(
          (e3) => e3.BNFT_DSGN_CD === nextE2.BNFT_DSGN_CD,
        );
        if (existCoverageCode) {
          //기존 급부 있으면 연산된 총액으로 합산
          existCoverageCode.COVERAGE_AMT += nextE2.COVERAGE_AMT * PN_UCN;
        } else {
          //없으면 깊은 값복사
          let copyedNextE2 = JSON.parse(JSON.stringify(nextE2));
          //총액 연산
          copyedNextE2.COVERAGE_AMT = copyedNextE2.COVERAGE_AMT * PN_UCN;
          //복사본 추가
          existRecord.computedDsdList.push(copyedNextE2);
        }
        return accu2;
      }, existRecord);
    } else {
      //합산레코드가 없으면 새로운 합산 레코드 생성 및 급부 정보 깊은 값복사
      //급부 정보 깊은 값복사
      let copyedComputedDsdList = JSON.parse(JSON.stringify(nextE1.computedDsdList));

      //급부정보의 금액을 총액 으로 연산
      copyedComputedDsdList.forEach((e, i, arr) => {
        arr[i].COVERAGE_AMT = arr[i].COVERAGE_AMT * PN_UCN;
      });

      accu1.push({
        CNR_ST_DT: nextE1.CNR_ST_DT,
        CNR_ED_DT: nextE1.CNR_ED_DT,
        ELPS_PD_MIN_VAL: nextE1.ELPS_PD_MIN_VAL,
        ELPS_PD_MAX_VAL: nextE1.ELPS_PD_MAX_VAL,
        IS_AE_MIN_VAL: nextE1.IS_AE_MIN_VAL,
        IS_AE_MAX_VAL: nextE1.IS_AE_MAX_VAL,
        computedDsdList: copyedComputedDsdList,
        DAT_BS_CD: '합산',
        PCN_VAL: 1,
      });
    }
    return accu1;
  }, []);

  //2건 이상 합산 결과 존재하면 전체 합산 데이터 제공
  if (SubTotalList.some((e) => 1 < e.PCN_VAL)) {
    return SubTotalList;
  } else {
    return [];
  }
};

/**
 * 수정할 DIF LIST atom
 */
export const difFormListState = atom({
  key: 'difFormListState',
  default: [],
});

/**
 * 주특약별 수정폼용 보장(DIF) 리스트를 구합니다.
 */
export const riderDifFormListState = selector({
  key: 'riderDifFormListState',
  get: ({ get }) => {
    //수정할 DIF LIST 구독
    const difFormList = get(difFormListState);
    if (difFormList.length < 1) return [];

    //DSD 구독
    const dsdList = get(dsdListState);

    //입력값으로 받은 DIF 리스트내에 DSD 배열 생성
    const computedDifList = difFormList.map((e1) => {
      //DSD급부별 배열 생성 및 보장금액 계산
      const computedDsdList = dsdList
        .filter((e2) => e2.DAT_BS_CD === e1.DAT_BS_CD)
        .map((e3) => {
          //1 * DIF배수 * DSD배수, 소숫점 1자리까지 표현한다.
          const COVERAGE_AMT = Number((Number(e1.PCN_VAL) * Number(e3.BAS_VAL)).toFixed(1));
          return { ...e3, COVERAGE_AMT };
        });

      //DIF에 DSD배열을 조립
      return { ...e1, computedDsdList };
    });

    //DSD 배열을 프로퍼티로 피벗
    const pivotedComputedDifList = computedDifList.map((e1) => {
      //DIF에 DSD배열을 필드로 피벗 시킴
      const pivotedDsd = e1.computedDsdList.reduce((prev, cur) => {
        prev['C_' + cur.BNFT_DSGN_CD] = cur.COVERAGE_AMT;
        return prev;
      }, {});

      //DIF에 DSD배열과 피벗된 필드를 조립
      return { ...e1, ...pivotedDsd };
    });

    //보장급부를 급부코드순, 시작/종료, 기소/기대, 연소/연대 순으로 정렬, 순번멕임
    pivotedComputedDifList
      .sort((a, b) => {
        if (Number(a.DAT_BS_CD) < Number(b.DAT_BS_CD)) return -1;
        if (Number(a.DAT_BS_CD) > Number(b.DAT_BS_CD)) return 1;

        if (a.CNR_ST_DT < b.CNR_ST_DT) return -1;
        if (a.CNR_ST_DT > b.CNR_ST_DT) return 1;

        if (a.CNR_ED_DT < b.CNR_ED_DT) return -1;
        if (a.CNR_ED_DT > b.CNR_ED_DT) return 1;

        if (a.ELPS_PD_MIN_VAL < b.ELPS_PD_MIN_VAL) return -1;
        if (a.ELPS_PD_MIN_VAL > b.ELPS_PD_MIN_VAL) return 1;

        if (a.ELPS_PD_MAX_VAL < b.ELPS_PD_MAX_VAL) return -1;
        if (a.ELPS_PD_MAX_VAL > b.ELPS_PD_MAX_VAL) return 1;

        if (a.IS_AE_MIN_VAL < b.IS_AE_MIN_VAL) return -1;
        if (a.IS_AE_MIN_VAL > b.IS_AE_MIN_VAL) return 1;

        if (a.IS_AE_MAX_VAL < b.IS_AE_MAX_VAL) return -1;
        if (a.IS_AE_MAX_VAL > b.IS_AE_MAX_VAL) return 1;

        return 0;
      })
      .forEach((e, i, arr) => {
        arr[i].seq = i;
      });

    return pivotedComputedDifList;
  },
});

/**
 * 회사별 약관(CODEX) 리스트를 구합니다.
 */
export const codexListState = selector({
  key: 'codexListState',
  get: async ({ get }) => {
    const companyCode = get(companyCodeState);
    if (companyCode === 'reset' || !companyCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/codexList/${companyCode}`,
      await defaultRequestObject('GET', {}, true),
    );
    return (await fetchCompressResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 상품별 약관(CODEX) 리스트를 구합니다.
 */
export const codexGoodListState = selector({
  key: 'codexGoodListState',
  get: ({ get }) => {
    //상품 아톰 구독
    const goodCode = get(goodCodeState);
    if (!goodCode) return '';

    //회사별 약관 리스트 구독
    const codexList = get(codexListState);

    //선택된 상품의 약관만 리턴
    return codexList.filter((e) => e.PDT_CD === goodCode);
  },
});

/**
 * 바닐라 전체 일별 입력 통계 데이터를 구합니다.
 */
export const statisticsAll = selector({
  key: 'vanillaStatisticsAll',
  get: async () => {
    const response = await fetch(
      `${getApiServerName()}/vanilla/statistics`,
      await defaultRequestObject('GET', {}, true),
    );
    return (await fetchResponseHandler(response)).message;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 바닐라 전체 일별 입력 통계 데이터를 구합니다.
 */
export const statistics = selectorFamily({
  key: 'vanillaStatistics',
  get:
    (gubun) =>
    async ({ get }) => {
      const response = get(statisticsAll);
      //4종의 데이터를 이용해서 복합 구조를 만든다.
      //1. 일별입력 상품/주특약 2종의 데이터를 이용하여 중복없는 입력 날짜 리스트를 구한다.
      let workedDayList = getWorkedDayList(
        response[0].filter((e) => e.GUBUN === gubun),
        response[1].filter((e) => e.GUBUN === gubun),
      );

      return workedDayList.map((e1) => {
        //일별 총상품 입력 데이터에 회사별 상품/주특약 입력 데이터를 자식으로 붙여 준다.
        const goodList = response[2]
          .filter((e2) => e2.REG_DTM === e1.REG_DTM && e2.GUBUN === gubun)
          .map((e3) => ({
            name: `상품 : ${e3.REFRN_CD_NM}`,
            date: e3.REG_DTM,
            value: e3.COUNT,
            min: e3.MIN,
            max: e3.MAX,
            isCmyCd: e3.IS_CMY_CD,
            level: 1,
          }));
        const riderList = response[3]
          .filter((e2) => {
            if (gubun === 1) {
              return e2.REG_DTM === e1.REG_DTM && Number(e2.IS_CMY_CD) < 170;
            } else {
              return e2.REG_DTM === e1.REG_DTM && 170 <= Number(e2.IS_CMY_CD);
            }
          })
          .map((e3) => ({
            name: `주특약 : ${e3.REFRN_CD_NM}`,
            date: e3.REG_DTM,
            value: e3.COUNT,
            min: e3.MIN,
            max: e3.MAX,
            isCmyCd: e3.IS_CMY_CD,
            level: 2,
          }));
        return {
          date: e1.REG_DTM,
          count: e1.COUNT,
          goodCount: e1.GOOD_COUNT,
          riderCount: e1.RIDER_COUNT,
          type: e1.TYPE,
          details: [...goodList, ...riderList].sort((a, b) => {
            let returnValue;
            //회사순으로 정렬
            if (a.isCmyCd < b.isCmyCd) {
              returnValue = -1;
            } else if (b.isCmyCd < a.isCmyCd) {
              returnValue = 1;
            } else {
              //동일한 군내에서는 상품, 주특약순으로 정렬
              returnValue = Number(a.level) < Number(b.level) ? -1 : 1;
            }
            return returnValue;
          }),
        };
      });
    },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

/**
 * 두개의 리스트에서 중복 없는 모든 날짜 리스트를 구합니다.
 * @param {*} listA
 * @param {*} listB
 */
const getWorkedDayList = (listA, listB) => {
  const workedDayList = [];
  listA.reduce((accu, cur) => {
    //동일 REG_DTM이 없으면 추가
    if (!accu.find((e) => e.REG_DTM === cur.REG_DTM)) {
      accu.push({
        REG_DTM: cur.REG_DTM,
        COUNT: cur.COUNT,
        GOOD_COUNT: cur.COUNT,
        RIDER_COUNT: 0,
        TYPE: `상품[${cur.COUNT}]`,
      });
    }
    return accu;
  }, workedDayList);

  listB.reduce((accu, cur) => {
    //동일 REG_DTM이 없으면 추가
    const exist = accu.find((e) => e.REG_DTM === cur.REG_DTM);
    if (exist) {
      exist.COUNT += cur.COUNT;
      exist.RIDER_COUNT += cur.COUNT;
      exist.TYPE += `/주특약[${cur.COUNT}]`;
    } else {
      accu.push({
        REG_DTM: cur.REG_DTM,
        COUNT: cur.COUNT,
        GOOD_COUNT: 0,
        RIDER_COUNT: cur.COUNT,
        TYPE: `주특약[${cur.COUNT}]`,
      });
    }
    return accu;
  }, workedDayList);
  //오래된 날짜부터 정렬
  return workedDayList.sort((a, b) =>
    a.REG_DTM < b.REG_DTM ? -1 : a.REG_DTM === b.REG_DTM ? 0 : 1,
  );
};

/**
 * 쌍둥이 주특약 선택 상태 atom
 */
export const twinsCodeState = atom({
  key: 'twinsCodeState',
  default: { bnftBsClCd: '', bnftBsItmCd: '', mcnrScnClCd: '' },
});

/**
 * 쌍둥이 주특약(MSS)리스트를 구합니다.
 */
export const twinsListState = selector({
  key: 'twinsListState',
  get: async ({ get }) => {
    const twinsCode = get(twinsCodeState);
    if (twinsCode.bnftBsClCd === '' || !twinsCode) return [];
    const response = await fetch(
      `${getApiServerName()}/vanilla/twinsMssList/${twinsCode.bnftBsClCd}/${
        twinsCode.bnftBsItmCd
      }/${twinsCode.mcnrScnClCd}`,
      await defaultRequestObject('GET', {}, true),
    );

    let list = (await fetchResponseHandler(response)).message;
    list.forEach((e, i, arr) => (e.SEQ = arr.length - i));
    return list;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});
