import { atom, selector } from 'recoil';
import { defaultRequestObject, fetchResponseHandler } from '../common';

export const userIdState = atom({
  key: 'userIdState',
  default: '',
});

export const userInfoState = selector({
  key: 'userInfoState',
  get: async ({ get }) => {
    // const userInfo = get(userIdState);

    //사용자 정보를 구합니다.
    const response = await fetchResponseHandler(
      await fetch(
        'https://graph.microsoft.com/oidc/userinfo',
        await defaultRequestObject('GET', {}, true, true),
      ),
    );

    //사용자의 관리자 권한을 설정 합니다. 2024.1.29 현재 박성훈, 김재형, 최규남, 김은총, 박찬, 문수민, 홍두기, 차민지, 김나현 관리자
    const adminList = [
      'hunine@dbdip.com',
      'francis@dbdip.com',
      'vegasenzo@dbdip.com',
      'iamreality@dbdip.com',
      'msm0723@dbdip.com',
      'ghdenrl20@dbdip.com',
      'ss43579@dbdip.com',
      'nh7656a@dbdip.com',
    ];
    response.admin = adminList.some((e) => e === response.email);
    return response;
  },
});
