const redirectUrlMap = {
  production: 'https://portal.dbdip.com/authcallbackspa',
  staging: 'https://staging-portal.dbdip.com/authcallbackspa',
  development: 'http://localhost:3001/authcallbackspa',
};

export const msalConfig = {
  auth: {
    clientId: '1695f393-323a-4cfa-aef7-fe2133c2b29e',
    authority: 'https://login.microsoftonline.com/9f890666-b156-403c-b4bc-28162857e554', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri:
      redirectUrlMap[process.env.REACT_APP_ENV] || 'http://localhost:3001/authcallbackspa',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
