import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import pako from 'pako';
import { Buffer } from 'buffer';

/**
 * 개발/운영으로 개별 api서버 주소를 설정합니다.
 */
const apiServerNames = {
  development: '/development',
  staging: '/staging',
  production: '/api',
  // production: 'https://jsonplaceholder.typicode.com/',
};

/**
 * api서버주소를 개발/운영환경에 맞추어 제공합니다.
 * @returns api서버주소
 */
export const getApiServerName = () => {
  const apiServerName = apiServerNames[process.env.REACT_APP_ENV];
  if (!apiServerName) {
    throw new Error(
      `실행 환경 설정 변수 REACT_APP_ENV 가 정확한 값이 아닙니다. 값 : "${process.env.REACT_APP_ENV}"`,
    );
  }
  return apiServerName;
};

/**
 * 가장 최신의 엑세스 토큰을 구합니다. 다음의 순서로 구합니다.
 * 1.캐쉬에 유효기간내의 토큰이 존재하면 그것을 사용
 * 2. 유효기간이 만료되었으면 갱신된 토큰을 인증서버에 요청하여 로그인이 되어 있으면 그것을 사용
 * 3. 로그인이 안되어 있으면 로그인페이지로 리다이렉션 시켜서 사용자에게 인증을 강제 시킴
 * @returns 갱신된 엑세스 토큰
 */
export const acquireToken = async () => {
  //억세스 토큰 획득
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ['user.read'],
    account: account,
  };

  let accessToken;

  try {
    //사용자와 인터렉티브한 UI없이 조용히 유효한 토큰 취득
    accessToken = (await msalInstance.acquireTokenSilent(accessTokenRequest)).accessToken;
  } catch (error) {
    console.log(error);
    //로그인상태가 아니라면 로그인페이지로 리다이렉션 시켜서 사용자에게 인증을 강제 시킴
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(accessTokenRequest);
    }
  }
  return 'Bearer ' + accessToken;
};

/**
 * 공통 fetch API response 처리 함수 정상(200~299)이외의 값에 대한 오류를 발생 시킴
 * @param {*} response fetch API의 response 객체
 * @returns 응답코드가 정상(200~299)이면 response의 json 객체, 그 외에는 에러를 발생시킵니다.
 */
export const fetchResponseHandler = async (response) => {
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(
      `서버API 호출 중 에러 발생, 응답코드 : ${response.status}, 상내 내용 : ${JSON.stringify(
        await response.json(),
      )}`,
    );
  }
};

/**
 * 공통 fetch API 압축된 response 처리 함수 정상(200~299)이외의 값에 대한 오류를 발생 시킴
 * @param {*} response fetch API의 압축된 response 객체
 * @returns 응답코드가 정상(200~299)이면 압축 해제된 response의 json 객체, 그 외에는 에러를 발생시킵니다.
 */
export const fetchCompressResponseHandler = async (response) => {
  if (response.ok) {
    let responseData = (await response.json()).compressed;
    responseData = Buffer.from(responseData, 'base64');
    try {
      //압축해제
      responseData = JSON.parse(pako.inflate(responseData, { to: 'string' }));
    } catch (e) {
      throw new Error(`서버API 호출 중 압축해제 에러 발생 상내 내용 : ${e}`);
    }

    return responseData;
  } else {
    throw new Error(
      `서버API 호출 중 에러 발생, 응답코드 : ${response.status}, 상내 내용 : ${JSON.stringify(
        await response.json(),
      )}`,
    );
  }
};

/**
 * 공통 fetch API request 객체 생성 함수
 * @param {*} method http 메서드 기본값 POST
 * @param {*} data body로 전송할 데이터 기본값 {}
 * @param {*} credentials OAuth 인증 정보 전송 유무 기본값 보냄(true)
 * @param {*} cors 크로스도메인(CORS)  사용 유무 기본값 false
 * @returns
 */
export const defaultRequestObject = async (
  method = 'POST',
  data = {},
  credentials = true,
  cors = false,
) => {
  let returnObject = {
    method: method,
    mode: cors ? 'cors' : 'same-origin',
    cache: 'no-cache',
    headers: {},
  };

  //GET,HEAD외의 요청은 body를 만들어서 입력데이터를 JSON 포맷으로 전송
  if (-1 === ['GET', 'HEAD'].indexOf(method.toUpperCase())) {
    returnObject.body = JSON.stringify(data);
    returnObject.headers = { 'Content-Type': 'application/json' };
  }

  //인증정보 전송이 필요하면 전송
  if (credentials) {
    returnObject.headers.Authorization = await acquireToken();
  }

  return returnObject;
};

/**
 * 공통 토스트 옵션 1.5초
 */
export const toastifyOptions = {
  autoClose: 1500,
};

/**
 * ag-grid의 날짜 필드용 선 date 형변환 후 비교 함수
 */
export const agGridDateFilterParams = {
  suppressAndOrCondition: true,
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;

    const cellDate = new Date(dateAsString);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

/**
 * 한글KSC5601코드로 인코딩했을때의 바이트수를 셉니다.
 * @param {*} str 바이트수를 셀 스트링
 * @returns 한글KSC5601코드로 인코딩했을때의 바이트수
 */
export const getKSC5601BytesFromString = (str) => {
  if (str === undefined) return 0;

  let str_character;
  let int_char_count = 0;
  let int_contents_length = str.length;

  for (let i = 0; i < int_contents_length; i++) {
    str_character = str.charAt(i);
    if (escape(str_character).length > 4) int_char_count += 2;
    else int_char_count++;
  }

  return int_char_count;
};
