import { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const CommonModal = (props) => {
  //최초 생성시엔 무조건 노출
  const [show, setShow] = useState(true);
  const loadingSymbol = {
    loading: '로딩 중 입니다.',
  };
  const message = loadingSymbol[props.message] || props.message;

  return (
    <>
      {loadingSymbol[props.message] ? (
        <LoadingModal show={show} />
      ) : (
        <ErrorModal show={show} message={message} setShow={setShow} />
      )}
    </>
  );
};

/**
 * 로딩 전용 모달
 * @param {*} props
 * @returns
 */
const LoadingModal = (props) => {
  return (
    <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div>로딩 중 입니다.</div>
      </Modal.Body>
    </Modal>
  );
};

/**
 * 에러 전용 모달
 * @param {*} props
 * @returns
 */
const ErrorModal = (props) => {
  return (
    <Modal show={props.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton onHide={() => props.setShow(false)}>
        <Modal.Title>에러 발생 안내</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-3">
          오류가 발생하였습니다. 본 화면을 캡쳐 혹은 카메라로 촬영하여 개발자에게 보내 주세요!
        </div>
        <div style={{ whiteSpace: 'pre-wrap' }}>{props.message}</div>
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
