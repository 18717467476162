import { useMsal } from '@azure/msal-react';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { companyCodeState, goodRequestTimeState, isGoodOrRiderState } from '../recoil/vanillaState';
import moment from 'moment';

/**
 * 좌측 사이드바 노출 여부 토글
 * @param {*} event
 */
const sidebarToggleHandler = (event) => {
  document.body.classList.toggle('sb-sidenav-toggled');
};

/**
 * 타이틀 문구 맵...새로운 화면이 추가되면 여기에 경로명과 화면명을 추가 합니다.
 */
const titleTextMap = new Map([
  ['/Dashboard/?', '대쉬보드'],
  ['/Vanilla/?', '바닐라'],
  ['/Miraeasset/?', '미래에셋생명-당사'],
  ['/Hanwhageneral/?', '한화손해-당사'],
]);

const logout = (instance) => {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
};

const Header = (props) => {
  const location = useLocation();
  const { instance } = useMsal();
  const setGoodRequestTime = useSetRecoilState(goodRequestTimeState);
  const setCompanyCodeState = useSetRecoilState(companyCodeState);
  const setIsGoodOrRiderState = useSetRecoilState(isGoodOrRiderState);

  let titleText;
  titleTextMap.forEach((value, key) => {
    //정규식으로 경로 조사하여 타이틀명을 설정합니다.
    let re = new RegExp(key);
    titleText = location.pathname.match(re) ? value : titleText;
  });

  const refreshData = () => {
    //전체 상품 목록을 새로 가져옴
    setGoodRequestTime(moment().format('YYYY-MM-DD HH:mm:ss') + '[재회차]');
    //나머지 데이터들의 목록 삭제
    setCompanyCodeState('reset');

    //상품상세보기 초기화
    setIsGoodOrRiderState({ type: '', data: '' });
  };

  return (
    <Navbar variant="dark" bg="dark" fixed="top" className="sb-topnav">
      <Navbar.Brand
        as={Link}
        to="/"
        className={'ps-3' + (props.title.indexOf('운영') < 0 ? ' bg-danger' : '')}>
        {props.title}
      </Navbar.Brand>
      <Button variant="link" onClick={sidebarToggleHandler} size="sm">
        <i className="fas fa-bars"></i>
      </Button>
      <div className="bg-dark text-white ms-3 me-auto">
        {titleText}
        <Button
          variant="warning"
          className={'ms-3 ' + (titleText === '바닐라' ? '' : 'd-none')}
          onClick={() => refreshData()}>
          <i className="fas fa-sync-alt pe-2"></i>
        </Button>
      </div>
      <Nav className="ms-auto me-3" activeKey="">
        <NavDropdown
          title={<i className="fas fa-user fa-fw"></i>}
          menuVariant="dark dropdown-menu-end">
          <NavDropdown.Item eventKey="Settings" as={Link} to="/Settings">
            설정
          </NavDropdown.Item>
          <NavDropdown.Item eventKey="LoginHistory" as={Link} to="/LoginHistory">
            로그인 이력
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="Logout" onClick={() => logout(instance)}>
            로그아웃
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

export default Header;
