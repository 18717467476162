import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../recoil/userState';

const Navigations = () => {
  //데이터 state 선언
  const userInfo = useRecoilValue(userInfoState);
  const location = useLocation();

  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark">
        <div className="sb-sidenav-menu">
          <Nav>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="Dashboard"
                active={0 <= location.pathname.indexOf('/Dashboard')}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-calendar-alt"></i>
                </div>
                대쉬보드
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="Vanilla" active={0 <= location.pathname.indexOf('/Vanilla')}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-ice-cream"></i>
                </div>
                바닐라
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="Rpot" active={0 <= location.pathname.indexOf('/Rpot')}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-percent"></i>
                </div>
                적용이율
              </Nav.Link>
            </Nav.Item>
            <div className="sb-sidenav-menu-heading">당사 관리툴</div>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="Hanwhageneral"
                active={0 <= location.pathname.indexOf('/Hanwhageneral')}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-list-ol"></i>
                </div>
                한화손해
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="Miraeasset"
                active={0 <= location.pathname.indexOf('/Miraeasset')}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-list-ol"></i>
                </div>
                미래에셋 생명
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">로그인 유저:</div>
          {userInfo.name}({userInfo.email})
        </div>
      </nav>
    </div>
  );
};

export default Navigations;
